@import "../../variables";

.wrapper {
  padding: 16px 24px;
  border-radius: 4px;
  background-color: color("white");
}

.is-square {
  padding: 24px;
}
