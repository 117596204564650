@import "../../variables";

.content {
  position: relative;
}

.photo {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  background-color: color("white");
  background-size: cover;
  background-position: center;
}

.chevron {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: transparentize(color("tropical-blue"), 0.7);

  &Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 3px;
    transform: translate(-50%, -50%);
    fill: color("blue-zodiac");
  }
}

.title {
  color: color("blue-zodiac");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.subtitle {
  color: color("waterloo");
  font-size: 11px;
  line-height: 15px;
}

.body {
  margin: 0 11px 0 8px;
}

.button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: left;

  &:focus {
    outline: none;
  }
}

.menu {
  position: absolute;
  width: 136px;
  top: 39px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(70, 103, 188, 0.15);
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  button {
    border-top: 1px solid color("background");

    &:hover {
      outline: none;
    }
  }

  button,
  a {
    display: flex;
    align-items: center;
    padding: 14px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: color("black");

    &:hover {
      background: rgba(195, 221, 246, 0.3);
    }
  }

  .iconWrapper {
    width: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.rotate {
  transform: rotate(180deg);
}
