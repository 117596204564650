@import "../../variables";

.button {
  width: 100%;
  padding: 14px 18px;
  transition: background $time-transition;
  background: color("white");
  text-align: left;

  & + & {
    border-top: 1px solid color("black-squeeze");
  }

  @include hover {
    background: transparentize(color("blue"), 0.9);
  }
}

.content {
  position: relative;
  padding-left: 22px;
  color: color("blue-zodiac");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  white-space: pre;
}

.icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  fill: color("gull-gray");
}

.warning {
  color: color("error");

  .icon {
    fill: color("error");
  }
}
