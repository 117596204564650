@import "../../variables";

.content {
  display: inline-flex;
}

.button {
  @include click-expand(10px);

  display: block;
  position: relative;
  width: 18px;
  height: 4px;

  > * {
    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      left: 50%;
      transform: translateX(-50%);
    }

    &:last-child {
      right: 0;
    }
  }

  @include hover {
    > * {
      background: color("blue-zodiac");
    }
  }
}

.drawer {
  display: flex;
  position: fixed;
  z-index: index("drawer");
  top: 0;
  left: 0;
  flex-direction: column;
  border-radius: 4px;
  background: color("white");
  box-shadow: 0px 0px 4px rgba(70, 103, 188, 0.15);
}

.dot {
  position: absolute;
  top: 0;
  width: 4px;
  height: 4px;
  transition: background $time-transition;
  border-radius: 50%;
  background: color("waterloo");

  .isActive & {
    background: color("blue-zodiac");
  }
}
