@import "../../variables";

.rechartsWrapper {
  padding-left: 44px;
}

.tooltipWrapper {
  background-color: color("white");
  width: 327px;
  height: 80px;
  padding: 16px 34px 16px 24px;
  box-shadow: 0 4px 24px rgba(244, 106, 65, 0.13);
  border-radius: 4px;
}

.responsiveContainer {
  overflow: hidden;

  @media (min-width: 769px) {
    :global(.recharts-cartesian-grid-vertical line) {
      transform: translateX(-20px);
    }
  }

  @media (min-width: 768px) {
    :global(.recharts-cartesian-grid-vertical line:last-of-type) {
      transform: translate(-5px);
    }

    :global(.recharts-cartesian-axis-ticks g:first-of-type) {
      transform: translate(10px);
    }
  }
}

.adminChart :global(.recharts-cartesian-grid-vertical line) {
  transform: translateX(-15px);
}
