@import "../../variables";

.whiteBox {
  width: 468px;
  margin: auto;
  padding: 16px 0 50px;
}

.title {
  font-weight: 300;
  font-size: 28px;
  line-height: 1;
  margin: 18px 0 24px 32px;
}

.fieldWrapper {
  border-bottom: 1px solid color(black-squeeze);
  display: flex;
  padding: 16px 32px;

  & > div {
    flex: 1;
  }

  .avatar {
    width: 98px;
    height: 98px;
    margin-right: 30px;
    background-size: cover;
    background-position: center;
  }
}

.content {
  display: flex;
  align-items: center;
  position: relative;
}

.img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
  object-fit: cover;
  background-color: color("botticelli");
  font-weight: 300;
  font-size: 28px;
  color: color("grey");
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 69px;
  background: color("white");
  box-shadow: 0px 0px 4px rgba(70, 103, 188, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5px 0 0 1px;

  &:focus {
    outline: none;
  }
}

.userBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.userName {
  display: flex;
  align-items: center;
  color: color("blue-zodiac");
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.email {
  margin: 8px 0 26px;
  color: color("gull-gray");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.changePassword {
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  padding: 0;
}

.name {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: color("waterloo");

  & > p {
    font-size: 20px;
  }
}

.value {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 600;

  :global(.group-icon) {
    justify-content: flex-end;
  }
}

.ecobeeIcon {
  width: 15px;
  height: 14px;
  fill: color("green-light");
}

.neurio {
  &Icon {
    width: 14px;
    height: 14px;
  }

  &Item {
    & + & {
      margin-top: 5px;
    }
  }
}

.powerIcon {
  width: 14px;
  height: 14px;
  fill: color("blue");
}

:global(.mobile) {
  .whiteBox {
    width: 100%;
    background: color("black-squeeze");
    padding: 0;
  }

  .modalIsOpen {
    padding: 0;
    margin-top: -40px;
  }

  .fieldWrapper {
    padding: 16px 24px;
    border-bottom: 1px solid color("white");

    &:first-child {
      padding-top: 0;
    }
  }

  .button {
    display: block;
    margin: 40px 24px 15px;
    width: calc(100% - 48px);
    color: color("black");
  }

  .button + .button {
    margin-top: 15px;
  }

  .envelopeIcon {
    height: 10px;
    fill: color("white");
  }

  .inviteFriendButton {
    color: color("white");
  }
}

.paymentMethod {
  svg {
    margin-top: -4px;
    vertical-align: middle;
    margin-left: 8px;
    height: 20px;
  }
}
