@import "../../variables";

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
}

.fieldItem {
  width: 400px;
  margin-bottom: 16px;
}

.loaderWrapper {
  width: 100%;
  height: 423px;

  div {
    margin: auto;
    height: 100% !important;
  }
}

:global(.mobile) {
  .header {
    background: transparent;
    padding: 0 0 32px;
  }

  .fieldItem {
    width: 100%;

    input {
      background: transparent;
    }
  }

  .chevronIcon {
    height: 23px;
    width: auto;
    stroke-width: 0.8px;
    margin-right: 8px;
  }

  .sendButton {
    padding: 6px 32px;
  }

  .loader {
    margin: 30px auto 0;
  }

  .changePasswordHeader {
    padding-top: 35px;
  }
}

.error {
  position: absolute;
  top: 0;
  margin-top: 5px !important;
}
