.blockWrapper {
  display: flex;
  justify-content: space-between;
  margin: 30px 50px 10px;
  min-height: 270px;
}

.verticalView {
  flex-direction: column;
  width: 140px;
  margin-left: 40px;
}
