.tooltip {
  cursor: pointer;

  tspan {
    visibility: hidden;
  }

  &:hover {
    tspan {
      visibility: visible;
    }
  }
}
