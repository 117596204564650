@import "../../variables";

.tooltipWrapper {
  background-color: color("white");
  width: calc(100vw - 48px);
  height: 80px;
  padding: 16px 24px;
  box-shadow: 0 4px 24px rgba(244, 106, 65, 0.13);
  border-radius: 4px;

  @media (min-width: 768px) {
    width: 340px;

    &.wideWindow {
      width: 500px;
    }
  }

  :global(.recharts-active-dot.recharts-active-dot) {
    circle.recharts-dot {
      display: none;
    }
  }
}

:global(.recharts-active-dot.recharts-active-dot) {
  :global(circle.recharts-dot) {
    display: none;
  }
}

:global(.recharts-tooltip-wrapper) {
  width: 100%;
}

:global(.recharts-curve.recharts-tooltip-cursor) {
  transform: translateY(-24px) scaleY(1.1);
}
