@import "../../variables";

.form {
  &Item {
    margin-bottom: 16px;
  }
}

.powerSupplierFormItem {
  margin: 16px 0 10px;
}

.button {
  &Primary {
    width: 160px;
  }

  &Secondary {
    margin-left: 16px;
  }
}

.icon {
  &Bee {
    fill: color("green-light");
  }
}

.inputArray {
  &Footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    &Link {
      margin-left: 16px;
    }
  }
}

.divider {
  position: relative;
  height: 1px;
  margin: 24px 0;
  overflow: visible;
  border: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background: color("black-squeeze");
  }
}

.hidden {
  display: none;
}

.error {
  position: absolute;
  top: 0;
  margin-top: 10px;
}
