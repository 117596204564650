@import "../../variables";

.headerWrapper {
  align-items: center;
}

.titleIcon {
  width: 20px;
  height: 20px;
  margin-right: 14px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: color("black");
  word-wrap: break-word;
  flex: 1;
}

.iconsWrapper {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.icons {
  width: 40px;
  height: 40px;
  background: #f3f7fb;
  border-radius: 50%;
  margin-left: 8px;
  border-style: solid;

  &:focus {
    outline: none;
  }
}

.activeIcon {
  border-width: 2px;
}

.blockWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .chartsWrapper {
    width: 110%;
    transform: translateX(-10px);
    margin-top: 32px;
    flex: 1;
  }

  :global(.recharts-wrapper) {
    height: 100% !important;

    svg {
      height: 100%;
    }
  }
}

.smallChart {
  :global(.recharts-wrapper) {
    svg {
      transform: scaleY(1.2);
    }
  }
}
