@import "../../variables";

.title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}

.dates {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: color("grey");

  svg {
    margin-right: 6px;
  }
}

.hide {
  visibility: hidden;
}
