@import "../../variables";

.title {
  font-size: 28px;
}

.dndBlock {
  margin: auto;
  width: 280px;
  outline: none;
}

.photoFiller {
  width: 280px;
  height: 280px;
  background: radial-gradient(transparent 70.6%, rgba(57, 57, 57, 0.5) 0),
    url(../../assets/icons/Photo.svg) #d4dae9 center/55px no-repeat;
}

.dndLabel {
  margin-top: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.button {
  text-decoration: underline;
  padding: 0;
}

.button {
  &Primary {
    width: 160px;
  }

  &Secondary {
    margin-left: 16px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.removeButton {
  color: color("error");
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0;

  &:hover {
    color: color("orange") !important;

    svg {
      fill: color("orange");
    }
  }

  svg {
    width: 12px;
    margin-right: 6px;
    fill: color("error");
  }
}

.editorContainer {
  position: relative;
}

.rotateButton {
  position: absolute;
  padding: 5px;
  top: 10px;
  right: 10px;
  outline: none;

  svg {
    height: 15px;
    fill: color("arctic-vanilla");
  }
}

.chevronIcon {
  margin-right: 8px;
  height: 22px;
  width: auto;
  stroke-width: 0.5px;
}

:global(.mobile) {
  .dndLabel {
    margin-bottom: 10px;
  }

  .modalHeaderContainer {
    padding-top: 0;
    background: color("background");
    padding-bottom: 8px;
  }

  .footer {
    position: static;
  }

  .removeButton {
    margin: 25px auto 0;
  }
}
