.container {
  height: 6px;
  background: #f3f7fb;
  border-radius: 2px;
  margin: 4px;
}

.filler {
  background: #52b4f8;
  border-radius: 2px;
  height: 100%;
  transition: width 1s ease-in-out;
}
