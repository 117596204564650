@import "../../variables";

.nominationWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
}

.headerWrapper {
  letter-spacing: 0.1px;
  color: color("black");
  border-bottom: 1px solid rgba(157, 165, 185, 0.2);
  padding: 0 32px 28px;
  margin-top: 32px;
  justify-content: space-between;
}

.headline {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  > span {
    display: inline-block;
    grid-area: header;
    text-align: center;
    margin-top: 8px;
  }

  > svg {
    grid-area: svg;
  }
  grid-template-areas:
    "svg header .";
}

.icon {
  width: 20px;
  height: 20px;
  margin: 8px 0 0 32px;
}

.title {
  font-weight: 600;
  font-size: 14px;
}

.dates {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: color("grey");

  svg {
    margin-right: 6px;
  }
}

.bill {
  font-weight: 600;
  font-size: 32px;
}

.descriptionBlock {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
