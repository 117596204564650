@import "../../variables";

.input {
  width: 300px;
  height: 36px;
  padding: 4px 10px 4px 40px;
  transition: border $time-transition;
  border: 1px solid color("white");
  border-radius: 4px;
  outline: 0;
  background-image: url("../../assets/icons/Search.svg");
  background-repeat: no-repeat;
  background-position: 17px center;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
  -webkit-appearance: none;

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: color("gull-gray");
  }

  &:hover {
    border-color: transparentize(color("blue"), 0.8);
  }

  &:focus {
    border-color: color("blue");
    outline: none;
  }
}
