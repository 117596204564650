@import "../../variables";

.modalHeader {
  margin: 15px 32px;
}

.smallTitle {
  font-size: 11px;
  color: color("waterloo");
}

.title {
  font-size: 28px;
  font-weight: 300;
  color: color("black");
}

.select {
  position: absolute;
  padding: 34px 70px 32px 32px;
}

.statisticsWrapper {
  margin-left: 32px;
  margin-bottom: 20px;
  width: unset;
}

.closeButton {
  width: 160px;
  height: 36px;
}
