@import "../../variables";

.wrapper {
  display: flex;
  flex-direction: column;
}

.name {
  margin-bottom: 4px;
  color: $color-grey;
  font-size: 12px;
  letter-spacing: 0.1px;
}

.number {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
