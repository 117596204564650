@import "./../../variables";

.element {
  display: block;
  position: relative;
}

.content {
  display: block;
  position: relative;
  z-index: 1;
}

.label {
  display: block;
  padding-bottom: 4px;
  color: color("blue-zodiac");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  cursor: pointer;
  text-transform: capitalize;
}

.input {
  position: relative;
  width: 100%;
  padding: 9px 16px;
  overflow: hidden;
  transition: border-color $time-transition;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: 0;
  letter-spacing: 0.1px;
  line-height: 20px;

  &Content {
    position: relative;
  }

  &.outline {
    border-color: color("botticelli");
    font-size: 14px;

    &.hasIcon {
      padding-left: 56px;
    }
  }

  &.clear {
    padding: 14px 16px;
    font-size: 12px;

    &.hasIcon {
      padding-right: 56px;
    }
  }

  &.error {
    border-color: color("error");

    ~ .iconContent {
      border-color: color("error");

      .icon {
        fill: color("error");
      }
    }
  }

  &:not(.error) {
    @include hover {
      border-color: color("blue");

      ~ .iconContent {
        border-color: color("blue");

        .icon {
          fill: color("blue");
        }
      }
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: fill $time-transition;
  fill: color("gull-gray");
  width: 16px;
  height: 16px;

  &Content {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 100%;
    transform: translateY(-50%);
    transition: border-color $time-transition;
    pointer-events: none;
    user-select: none;

    &.clear {
      right: 0;
      width: 58px;
    }

    &.outline {
      left: 0;
      width: 40px;
      height: calc(100% - 2px);
      border-right: 1px solid color("botticelli");
    }
  }
}
