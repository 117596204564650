@import "../../variables";

.container {
  &:not(.mobile) {
    @include space-x(40px);
    padding: 19px 50px;
  }

  &:global(.mobile) {
    padding: 0 24px;
    flex-direction: column;

    :global(.col-4),
    :global(.col-6) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  :global(.col-4) {
    margin-bottom: 40px;
  }

  .icon {
    width: 18px;
    height: 20px;
  }
}

.delimiter {
  height: 1px;
  opacity: 0.2;
  background: color("gull-gray");
  margin: 30px -32px 24px;
  width: calc(100% + 64px);
}

.headerWrapper {
  letter-spacing: 0.1px;
  color: color("black");
  margin: 32px 0;
  justify-content: space-between;
}

.bill {
  font-weight: 600;
  font-size: 32px;
}

.peakRow {
  justify-content: space-between;
  margin-bottom: 16px;
  color: color("waterloo");
}

.values {
  flex-direction: row;
}

.money {
  color: color("black");
}

.peakTitle {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 6px;
  }
}

.blockWrapper {
  padding: 32px 32px 24px;
}

:global(.mobile) {
  .blockWrapper {
    padding: 32px 24px 24px;
  }

  :global {
    .col-4,
    .col-6 {
      margin-bottom: 16px;
    }
  }
}

.notice {
  padding: 15px 40px 15px 20px;
  background-color: color("arctic-vanilla");
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;

  .percentage {
    margin-right: 20px;
  }
}

.centeredTitle {
  div:first-of-type {
    align-items: center;
    display: flex;
    margin: 0;
  }

  div:nth-of-type(2) {
    display: none;
  }
}

.btnIndentation {
  margin-left: 17px;
}
