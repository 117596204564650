@import "../../variables";

.fieldWrapper {
  border-bottom: 1px solid color(black-squeeze);
  display: flex;
  padding: 16px 32px;

  & > div {
    flex: 1;
  }

  .avatar {
    width: 98px;
    height: 98px;
    margin: 0 30px 8px 0;
  }
}

.name {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: color("waterloo");

  & > p {
    font-size: 20px;
  }
}

.value {
  text-align: right;
  font-size: 14px;
  font-weight: 600;

  :global(.group-icon) {
    justify-content: flex-end;
  }
}

.ecobeeIcon {
  width: 15px;
  height: 14px;
  fill: color("green-light");
}

.neurio {
  &Icon {
    width: 14px;
    height: 14px;
  }

  &Item {
    & + & {
      margin-top: 5px;
    }
  }
}

.powerIcon {
  width: 14px;
  height: 14px;
  fill: color("blue");
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.baselineWrapper {
  margin-bottom: 93px;
}

.savingShare {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: -7px;
}

.editButton {
  height: 15px;
  margin-left: 10px;
}

.editIcon {
  width: 15px;
}

.formWrapper {
  margin-bottom: 93px;
}
