@import "../../variables";

.tableWrapper {
  margin: 0 50px;
}

.table {
  width: 100%;
  min-width: 1340px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: color("white");

  &Wrapper {
    overflow-x: auto;
  }

  &Head {
    border: 1px solid color("black-squeeze");
  }

  tr > td {
    padding: 10px;
  }

  tr > * {
    text-align: left;
  }

  tr + tr > td {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: color("black-squeeze");
    }

    &:first-child,
    &:last-child {
      &:before {
        width: calc(100% + 20px);
      }
    }

    &:first-child {
      &:before {
        left: -20px;
      }
    }
  }

  tr > th {
    position: relative;
    padding: 15px 11px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: color("black-squeeze");
    }

    &:first-child,
    &:last-child {
      &:before {
        width: calc(100% + 20px);
      }
    }

    &:first-child {
      &:before {
        left: -20px;
      }
    }
  }

  &Title {
    color: color("waterloo");
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 17px;
    text-transform: uppercase;
  }
}

td {
  color: color("waterloo");
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.sort {
  display: flex;
  align-items: center;

  &Icon {
    width: 8px;
    height: 11px;
    margin-left: 6px;
    transition: fill $time-transition;
    fill: color("waterloo");
  }

  &Title {
    transition: color $time-transition;
  }

  @include hover {
    .sort {
      &Icon {
        fill: color("blue");
      }

      &Title {
        color: color("blue");
      }
    }
  }
}

.rotatedArrow {
  transform: rotate(180deg);
}
