@import "../../variables";

.description {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 25px;
  display: inline-block;
}

.cardList {
  margin: 0 -32px;
}

.cardRow {


  &.withBorder {
    border-top: 1px solid color("black-squeeze");
  }

  .radioContainer {
    margin-right: 16px;
  }

  .cardDescription {
    font-weight: 600;
    font-size: 14px;
  }

  button {
    width: 100%;
    min-width: 460px;
    outline: none;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    font-family: Manrope, Arial, sans-serif;
  }

  svg {
    height: 16px;
    vertical-align: middle;
  }
}

.footer {
  button {
    min-width: 160px;
  }
}
