.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  width: 18px;
  height: 20px;
  margin-right: 20px;
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
}
