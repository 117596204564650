@import "../../variables";

.dashboardWrapper {
  padding-bottom: 83px;
}

.chevronIcon {
  height: 17px;
  width: 8.5px;
  margin-right: 8px;

  path {
    stroke: #0f1f48;
  }
}

:global(.mobile) {
  .chevronIcon {
    height: 22px;
    width: auto;
    stroke-width: 0.5px;
  }
}
