.link {
  transition: color $time-transition;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 14px;
    
  &.default {
    color: color("blue");
  }

  &.error {
    color: color("error");
  }
}
