@import "../../variables";

.table {
  width: 100%;
  border-spacing: 0;

  th {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    padding: 15px;
    color: color("waterloo");
  }

  td {
    font-weight: 600;
    font-size: 14px;
    color: color("blue-zodiac");
    padding: 24px 15px;
    border-top: 1px solid color("black-squeeze");
  }
}

:global(.mobile) {
  .table {
    tr {
      th,
      td {
        padding: 15px 8px;
      }

      th:first-of-type,
      td:first-of-type,
      th:last-of-type,
      td:last-of-type {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
