@import "../../variables";

.content {
  display: flex;
  align-items: center;
}

.name {
  @include space-x(7px);

  display: flex;
  align-items: center;
  color: color("blue-zodiac");
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
  }
}

.img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
  object-fit: cover;
  background-color: color("background");
  background-size: cover;
  background-position: center;
}

.email {
  margin-top: 5px;
  color: color("gull-gray");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.badge {
  padding: 2px 8px;
  border-radius: 4px;
  background: color("sandy-brown");
  color: color("white");
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
}
