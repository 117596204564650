@import "../../variables";

.title {
  font-size: 28px;
  margin-left: 128px;
  font-weight: 300;
}

.space {
  @include space(9px);

  @include media("tablet") {
    @include space(40px);
  }
}

.desktopBlockIndent {
  padding: 24px 0;
  height: 100%;
}

.sideIndent {
  padding-left: 32px;
  padding-right: 32px;
}

.payNowButton {
  margin-right: 15px;
}

.descriptionBlock {
  padding: 24px 32px 0 32px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: color("waterloo");
}

.peakRow {
  justify-content: space-between;
  margin-bottom: 16px;
}

.values {
  flex-direction: row;
}

.money {
  color: color("black");
}

.peakTitle {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 6px;
  }
}

.savingsDescription {
  display: flex;
  flex-direction: row;
  color: color("black");
}

.percentage {
  margin-right: 14px;
  font-weight: 500;
  font-size: 16px;
}

.positive {
  color: color("light-green");
}

.negative {
  color: color("error");
}

.viewReportIndent {
  padding-left: 20px;
}

.flexBlock {
  display: flex;
  height: auto;
  width: 100%;
}
