@import "../../variables";

.form {
  &Item {
    margin-bottom: 16px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

:global {
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 1000000 !important;
  }
  .react-datepicker__day--selected {
    background-color: #d4dae9;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__input-container input {
    font-size: 14px;
    border: 1px solid #d4dae9;
    border-radius: 4px;
    padding: 10px 16px;
    width: 100%;
    &:hover {
      border-color: #52b2f7;
    }
  }
  .react-datepicker__navigation {
    border: 0.45rem solid transparent;
  }
  .react-datepicker__navigation--next {
    border-left-color: #d4dae9;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #d4dae9;
  }
}

.date {
  > p {
    font-size: 12px;
    color: colorBlack;
    padding-bottom: 4px;
  }
}

.subtitle {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $color-black;
  line-height: 0.1em;
  margin: 10px 0 20px;
  margin-top: 16px;

  span {
    background: #fff;
    padding: 0 10px;
    font-size: 18px;
  }
}

.table {
  margin: auto;
  th,
  td {
    text-align: center;
    padding: 0 5px;
  }
}

.subHeader {
  width: 100%;
  text-align: center;
  border-bottom: 1px dotted $color-black;
  line-height: 0.1em;
  margin: 10px 0 20px;
  margin-top: 16px;

  span {
    background: #fff;
    padding: 0 10px;
    font-size: 15px;
  }
}

.innerText {
  text-align: center;
  font-size: 15px;
}

.fieldWrapper {
  position: relative;
}

.dollar {
  position: absolute;
  z-index: 2;
  left: 1.5%;
  top: 50%;
  font-size: 14px;
}

.powerSupplierFormItem {
  margin: 16px 0 10px;
}

.button {
  &Primary {
    width: 160px;
  }

  &Secondary {
    margin-left: 16px;
  }
}

.icon {
  &Bee {
    fill: color("green-light");
  }
}

.rateHeader {
  margin-top: 16px;
  font-size: 20px;
  text-align: center;
}

.inputArray {
  &Footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;

    &Link {
      margin-left: 16px;
    }
  }
}

.divider {
  position: relative;
  height: 1px;
  margin: 24px 0;
  overflow: visible;
  border: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background: color("black-squeeze");
  }
}

.hidden {
  display: none;
}

.error {
  position: absolute;
  top: 0;
  margin-top: 10px;
}
