.wrapper {
  display: flex;
  padding: 0 25px;

  & > div {
    flex: 1;
  }

  @media (min-width: 768px) {
    width: 330px;
    padding: 0;
    margin-left: 128px;
  }
}

.units {
  font-size: 12px;
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  background-color: white;

  & > div {
    display: flex;
    & > div {
      flex: 1;
      padding: 12px 28px;
    }
  }
}
