@import "../../variables";

.rechartsWrapper {
  padding-left: 44px;
}

.tooltipWrapper {
  background-color: color("white");
  width: 327px;
  height: 80px;
  padding: 16px 34px 16px 24px;
  box-shadow: 0 4px 24px rgba(244, 106, 65, 0.13);
  border-radius: 4px;
}

.responsiveContainer {
  overflow: hidden;

  :global(.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis) {
    transform: translate(30px, 0px);
  }

  :global(.recharts-cartesian-grid .recharts-cartesian-grid-vertical) {
    line:last-of-type, line:nth-last-of-type(2) {
      display: none;
    }
  }
}
