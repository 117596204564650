@import "../../variables";

.element {
  padding: 11px 4px;
  text-align: center;
}

.header {
  margin-bottom: 9px;
  font-size: 0;
}

.icon {
  width: 20px;
  height: 20px;

  [color="primary"] {
    fill: color("waterloo");
  }

  [color="secondary"] {
    fill: color("botticelli");
  }

  [color] {
    transition: fill $time-transition;
  }
}

.title {
  color: color("grey");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.isActive {
  .title {
    color: color("blue");
  }

  [color="primary"] {
    fill: color("blue");
  }

  [color="secondary"] {
    fill: color("tropical-blue");
  }
}
