@import "../../variables";

.element {
  margin-top: 16px;
  color: color("error");
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 16px;
  text-align: center;
}
