@import "../../variables";

.stepper {
  font-size: 11px;
  color: color("waterloo");
  position: fixed;
  top: 15px;
  left: 30px;
  z-index: 3;
}

.goBack {
  position: absolute;
  top: 35px;
  right: 32px;
  z-index: 3;
}
