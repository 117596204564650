@import "../../variables";

@function calculateCell($width) {
  @return $width / 1300 * 100%;
}

.account {
  width: calculateCell(250);
}

.address {
  width: calculateCell(250);
}

.pick {
  width: calculateCell(160);
  color: color("black");
}

.ecobee {
  width: calculateCell(138);

  &Icon {
    width: 15px;
    height: 14px;
    fill: color("green-light");
  }
}

.neurio {
  width: calculateCell(168);

  &Icon {
    width: 14px;
    height: 14px;
  }

  &Item {
    & + & {
      margin-top: 5px;
    }
  }
}

.power {
  width: calculateCell(180);

  &Icon {
    width: 14px;
    height: 14px;
    fill: color("blue");
  }
}

.balance,
.accountId {
  width: calculateCell(108);
  color: color("blue-zodiac");
}

.bill {
  width: calculateCell(138);
  color: color("blue-zodiac");
}

.menu {
  font-size: 0;
}

.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  align-items: flex-start;
}

.delimiter {
  color: #7b8399;
}
