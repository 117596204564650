.group-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    display: block;
    margin-right: 8px;
  }
}
