@import "~@bpiwek/frontbox-style/index";
@import "assets/style/variables/colors";
@import "assets/style/variables/indexes";
@import "assets/style/variables/breakpoints";
@import "assets/style/variables/base";

$color-dark-blue: #5e69f7;
$color-blue: #52b2f7;
$color-white: #fff;
$color-red: #f86652;
$color-orange: #e88e64;
$color-yellow: #f7d039;
$color-green: #36c2b4;
$color-grey: #9da5b9;
$color-form-grey: #7b8399;
$color-black: #0f1f48;
$color-background: #f3f7fb;

:export {
  colorDarkBlue: $color-dark-blue;
  colorBlue: $color-blue;
  colorWhite: $color-white;
  colorRed: $color-red;
  colorOrange: $color-orange;
  colorYellow: $color-yellow;
  colorGreen: $color-green;
  colorGrey: $color-grey;
  colorFormGrey: $color-form-grey;
  colorBlack: $color-black;
  colorBackground: $color-background;
}
