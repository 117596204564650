@import "./../../variables";

.container {
  position: fixed;
  z-index: index("loading");
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: color("background");
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 159px;
  height: 154px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}

.element {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}

@keyframes loader-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(24px, 0, 0) scale(0.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(-24px, 0, 0) scale(0.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(0, 24px, 0) scale(0.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(0, -24px, 0) scale(0.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader {
  width: 32px;
  height: 32px;
  animation: loader-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    width: 10px;
    height: 10px;
    margin: auto;
    animation: loader-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    border-radius: 50%;
    background: color("blue");
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: 10px;
    height: 10px;
    margin: auto;
    animation: loader-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    border-radius: 50%;
    background: color("blue");
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 32px;
    height: 32px;
    margin: auto;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      width: 10px;
      height: 10px;
      margin: auto;
      animation: loader-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      border-radius: 50%;
      background: color("blue");
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      width: 10px;
      height: 10px;
      margin: auto;
      animation: loader-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      border-radius: 50%;
      background: color("blue");
    }
  }
}

.loaderWrapper {
  position: relative;

  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
