@include browser-reset-style;

body {
  min-height: 100vh;
  margin: 0;
  background: color("background");
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}

#root {
  min-height: 100vh;
}

.clickExpand {
  @extend %click-expand-style;
}
