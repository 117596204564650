@import "../../variables";

.rateModalWrapper {
  .fieldWrapper {
    border-bottom: 1px solid color(black-squeeze);
    display: flex;
    padding: 16px 32px;

    & > div {
      flex: 1;
    }

    > div:nth-child(2) {
      max-width: 200px;
    }

    .avatar {
      width: 98px;
      height: 98px;
      margin: 0 30px 8px 0;
    }
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: color("waterloo");

    & > p {
      font-size: 20px;
    }
  }

  .value {
    text-align: right;
    font-size: 14px;
    font-weight: 600;

    :global(.group-icon) {
      justify-content: flex-end;
    }
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 32px;
  }

  .react-datepicker-wrapper {
    max-width: 200px;
  }

  .react-datepicker-popper {
    z-index: 3 !important;
  }

  .react-datepicker__day--selected {
    background-color: color("blue");
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__input-container input {
    font-size: 14px;
    border: 1px solid color("blue");
    border-radius: 4px;
    padding: 8px 14px;
  }
  .react-datepicker__navigation {
    border: 0.45rem solid transparent;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
  }

  .historyLabel {
    margin: 40px 0 10px;
  }

  .history {
    padding: 0 32px;
  }

  .tableWrapper {
    display: grid;
    grid-template-columns: repeat(3, auto);
    font-size: 14px;
    min-height: 203px;
    max-height: 299px;
    overflow: auto;
  }

  .box {
    padding: 5px;
  }

  .row {
    display: contents;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .baselineWrapper {
    margin-bottom: 93px;
  }

  .savingShare {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -7px;
  }

  .editButton {
    height: 15px;
    margin-left: 10px;
  }

  .editIcon {
    width: 15px;
  }

  .formWrapper {
    margin-bottom: 93px;
  }
}
