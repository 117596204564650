@import "../../variables";

.button {
  padding: 6px 17px;
  transition: background $time-transition, color $time-transition;
  font-family: "Manrope";
  font-size: 14px;
  line-height: 23px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: color("grey");
    color: color("white");
    cursor: not-allowed;
  }
}

.outlinedButton {
  font-weight: 500;
  border-width: 0;
  border-radius: 4px;
  background: color("blue");
  color: $color-white;

  @include hover {
    &:not(:disabled) {
      background: darken(color("blue"), 10%);
    }
  }
}

.textButton {
  font-weight: 600;
  color: color("blue");

  @include hover {
    &:not(:disabled) {
      color: darken(color("blue"), 25%);
    }
  }
}
