$colors: (
  "darker-blue": #606def,
  "dark-blue": #5e69f7,
  "blue": #52b2f7,
  "white": #fff,
  "orange":  #F19441,
  "yellow": #f7d039,
  "green": #36c2b4,
  "light-green": #61bfb4,
  "grey": #9da5b9,
  "black": #0f1f48,
  "background": #f3f7fb,
  "arctic-vanilla": #fafcff,
  "swans-down": #d4dae9,
  "waterloo": #7b8399,
  "blue-zodiac": #0f1f48,
  "error": #f86652,
  "tropical-blue": #c3ddf6,
  "gull-gray": #9da5b9,
  "black-squeeze": #f3f7fb,
  "sandy-brown": #f7966a,
  "green-light": #5bbd76,
  "botticelli": #d4dae9,
  "fountain-blue": #61bfb4,
  "dark-grey": #6F6F6F,
  "cyan": #2DCBD2,
  "dark-ocean": #1796CD
);

:export {
  @each $name, $color in $colors {
    #{$name}: #{$color};
  }
}
