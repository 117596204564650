@import "../../variables";

.header {
  display: flex;
  margin-bottom: 24px;
}

.inviteButton {
  width: 160px;
  margin-left: auto;
}

.filter {
  display: flex;
  align-items: center;
  margin-left: 32px;
  border: 0;
  background: none;
  cursor: pointer;

  &Icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transition: fill $time-transition;
    fill: color("gull-gray");
  }

  &Title {
    transition: color $time-transition;
    color: color("gull-gray");
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 20px;
  }

  &:hover {
    .filter {
      &Icon {
        fill: color("blue");
      }

      &Title {
        color: color("blue");
      }
    }
  }
}
