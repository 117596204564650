@import "../../variables";

.contentWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &>div {
    display: flex;
    align-items: center;
    color: color("green-light");
  }

  svg {
    margin-right: 10px;
    circle {
      fill: color("green-light");
    }
  }
}
