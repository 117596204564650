@import "../../variables";

.pageWrapper {
  margin: 0 50px;

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: color("black");
    margin-bottom: 8px;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.1px;
    color: color("waterloo");
  }
}

.questions {
  padding: 0;
  position: fixed;
  width: 30vw;
}

.questionsBlock {
  padding: 32px;
}

.questionsLink {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  margin-bottom: 18px;
  color: color("black");
  cursor: pointer;
}

.questionNumber {
  margin-right: 3px;
}

.activeQuestion {
  color: color("blue");
}

.questionsBottomBlock {
  padding: 32px;
  border-top: 1px solid color("background");

  h6 {
    margin-bottom: 16px;
  }

}

.answers {
  padding: 0;
  width: 60vw;
  margin-left: auto;
}

.element {
  border-bottom: 1px solid color("background");
  padding: 24px 32px;
}

.answersBottomBlock {
  padding-bottom: 180px;
}

:global(.mobile) {
  .pageWrapper {
    margin: 0 24px;
  }

  .questions {
    position: static;
    width: 100%;
    margin-bottom: 10px;
  }

  .answers {
    width: 100%;
  }
}
