@import "../../variables";

%sticky {
  position: sticky;
  z-index: 2;
  left: 0;
  width: 100%;
  padding-top: 34px;
  padding-bottom: 24px;
  background: color("white");
}

.wrap {
  padding: 0 32px;
}

.wrapper {
  position: relative;
}

.close {
  &Icon {
    width: 14px;
    height: 14px;
    transition: fill $time-transition;
    fill: color("gull-gray");
  }

  &Button {
    @include click-expand(10px);

    position: absolute;
    z-index: 10;
    top: 50px;
    right: 35px;
    font-size: 0;
    cursor: pointer;

    @include hover {
      .closeIcon {
        fill: color("blue");
      }
    }
  }
}

.header {
  @extend %sticky;

  top: 0;
  width: 100%;
  padding-top: 34px;
  padding-bottom: 24px;
  z-index: 5;

  &Wrapper {
    position: relative;
    padding-right: 30px;
  }
}

.content {
  //@include vh("height", 100vh);

  max-height: 90vh;
  overflow-y: auto;

  main {
    overflow-y: auto;
  }

  footer {
    @extend %sticky;

    bottom: 0;

    & > .wrap {
      display: flex;
    }
  }
}

:global(.mobile) {
  .wrap {
    padding: 0 24px;
  }
}
