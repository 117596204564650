@import "../../variables";

.container {
  min-height: 100vh;
  background: $color-background url("../../assets/images/background-mask.png")
    no-repeat fixed center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoImg {
  height: 126px;
  margin-bottom: 40px;
}

.notice {
  text-align: center;
  line-height: 1.6;
  font-size: 24px;
  font-weight: 300;
  max-width: 670px;
  margin-bottom: 70px;
}

.wrap {
  @include wrap(327px, 24px);
  padding: 32px 0;
}

.rowInputs {
  @include space-x(17px);
}

.title {
  margin-bottom: 10px;
  color: color("blue-zodiac");
  font-size: 34px;
  font-weight: 300;
  line-height: 44px;

  @include media("tablet") {
    font-size: 28px;
    line-height: 39px;
  }
}

.subtitle {
  color: color("waterloo");
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.header {
  margin-bottom: 41px;
  text-align: center;
}

.content {
  @include vh("min-height", 100vh);

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 20px;

  @include media("tablet") {
    padding: 25px 0;
  }
}

.footer {
  margin-top: auto;
  padding-top: 24px;
}

.form {
  &Item {
    margin-bottom: 16px;
  }
}

.h2 {
  margin: 32px 0 16px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.button {
  &Send {
    display: block;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 14px;
  }
}

.cancelButton {
  margin-top: 14px;
}

.modalContent {
  height: auto;
}
