@import "../../variables";

.additionalServicesModal {
  .title {
    margin: 10px 0;
  }

  .field {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fieldWrapper {
      position: relative;
      max-width: 200px;

      .dollar {
        position: absolute;
        z-index: 2;
        left: 1.9%;
        top: 19%;
        font-size: 14px;
      }
    }

    > div {
      input {
        border-color: #52b2f7;
        padding: 6px 14px;
      }
    }
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .react-datepicker-wrapper {
    max-width: 200px;
  }

  .react-datepicker__day--selected {
    background-color: color("blue");
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__input-container input {
    font-size: 14px;
    border: 1px solid color("blue");
    border-radius: 4px;
    padding: 8px 14px;
  }
  .react-datepicker__navigation {
    border: 0.45rem solid transparent;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
  }

  .historyLabel {
    margin: 40px 0 10px;
  }

  .tableWrapper {
    display: grid;
    grid-template-columns: repeat(3, auto);
    font-size: 14px;
    min-height: 203px;
    max-height: 299px;
    overflow: auto;
  }

  .box {
    padding: 5px;
  }

  .row {
    display: contents;
  }
}
