@import "../../variables";

.container {
  @include media("tablet") {
    height: 108px;
    //margin-bottom: 10px;
  }
}

.header {
  position: relative;
  z-index: index("header");
  padding: 30px 0;

  @include media("tablet") {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    padding: 27px 0;
    background: color("background");
  }
}

.content {
  @include media("tablet") {
    @include space-x(20px);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.profile {
  margin-left: 34px;
}

.referralLink {
  margin-left: 16px;
}

.title {
  color: color("blue-zodiac");
  font-size: 34px;
  font-weight: 300;
  line-height: 44px;

  @include media("tablet") {
    font-size: 28px;
    line-height: 36px;
  }
}

.logo {
  margin-bottom: 27px;
  font-size: 0;
  display: flex;

  &Icon {
    width: 36px;
    height: 36px;
    cursor: pointer;

    @include media("tablet") {
      width: 53px;
      height: 53px;
    }
  }

  @include media("tablet") {
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.mobileHeaderNavigation {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 15px;

  &Icon {
    width: 10px;
    height: 10px;
    margin-right: 7px;
    fill: color("blue-zodiac");
  }
}

.column {
  @include media("tablet") {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.information {
  flex-basis: 327px;
  width: 327px;
  margin-right: 42px;
}

.notification {
  justify-content: center;
}

.faqIcon {
  margin-top: 5px;
}

:global(.mobile) {
  .title {
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;
  }
}
