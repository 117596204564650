@import "../../variables";

.element {
  padding: 24px;
  border-radius: 4px;
  background-color: color("white");
}

.header {
  margin-bottom: 36px;
}

.icon {
  width: 20px;
  height: 20px;
}

@media (max-device-width: 768px) {
  .element {
    padding: 24px 20px;
  }
}
