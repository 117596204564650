.currentUsageTitle {
  margin-bottom: 35px;
}

.circleChart {
  height: 190px;
}

:global(.mobile) {
  .circleChart {
    height: 140px;
  }
}
