@import "../../variables";

.dropZone {
  border: 1px solid color("botticelli");
  border-radius: 4px;
}

.error {
  border-color: color("error");
}

.mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  position: relative;
}

.uploadButton {
  width: 100%;
  height: 50px;
  background: #c3ddf6;
  border-radius: 4px;
  font-weight: 500;
  color: #52b4f8;
  border-color: #c3ddf6;
  margin-top: 50px;

  &:hover {
    color: white;
  }
}

.uploadTitle {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #0f1f48;
  margin-top: 15px;
}

.errorMessage {
  font-size: 12px;
  letter-spacing: 0.1px;
  color: color("error");
  position: absolute;
  bottom: 75px;
}
