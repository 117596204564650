@import "../../variables";

.title {
  font-size: 28px;
}

.fieldItem {
  margin-bottom: 16px;
  width: 42px;
}

.fieldItem1 {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid; // Gull Gray
  border-color: rgba(157, 165, 185, 0.2) !important;
  padding: 6px 0;

  &:active,
  &:focus {
    border-color: color("black") !important;
  }
}

.goBack {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: color("waterloo");
  margin-left: auto;

  svg {
    margin-right: 5.5px;
  }
}

.hidden {
  display: none;
}

.error {
  position: absolute;
  top: 0;
  margin-top: 10px;
}

.groupLabel {
  text-transform: capitalize;
  margin-bottom: 10px;
}

.inputsWrapper {
  display: flex;

  & > div:not(:last-of-type) {
    margin-right: 10px;
  }
}

.baselineTable {
  border: 1px solid color("botticelli");
  border-radius: 4px;
  table-layout: fixed;
}

.tableHead {
  background: color("background");
}

.disabledInput {
  margin: 0;

  input:disabled {
    border: none;
    background: none;
  }
}
