@import "../../variables";

.container {
  @include vh("min-height");

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 0;
  background: $color-background url("../../assets/images/background-mask.png") no-repeat fixed center;

  @include media("tablet") {
    justify-content: center;
  }
}

.content {
  &NavSpace {
    padding-bottom: 48px;
  }
}

.wrap {
  @include wrap(327px, 24px);
}

.logo {
  margin-bottom: 40px;
  font-size: 0;
  text-align: center;

  &Img {
    width: 126px;
    height: 126px;
  }
}

.title {
  line-height: 44px;
  text-align: center;
}

.subtitle {
  margin-top: 16px;
  color: color("waterloo");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
}

.header {
  margin-bottom: 70px;
  text-align: center;
}

.navLink {
  display: block;
  margin-top: 32px;
  color: $color-form-grey;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  text-align: center;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerInputs {
  > * + * {
    margin-top: 24px;
  }
}

.fieldWrapper {
  position: relative;
}

.invalidField {
  input {
    border-color: $color-red;
  }

  .error {
    position: absolute;
    bottom: -17px;
    left: 0;
    width: 100%;
    line-height: 16px;
  }
}

.smallTitle {
  margin-top: calc(100vh * 0.0123);
  font-size: 12px;
  letter-spacing: 0.1px;
}

.error {
  @extend .smallTitle;

  color: $color-red;
}

.message {
  @extend .smallTitle;

  color: $color-green;
}

.submitButton {
  margin-top: 32px;
  padding: 10px 0;
  line-height: 30px;
}

.inputs {
  > * + * {
    margin-top: 24px;
  }

  .authForm {
    width: 327px;
  }
}
