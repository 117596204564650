@import "./../../variables";

.element {
  display: block;
}

.content {
  display: block;
  position: relative;
  z-index: 1;
}

.label {
  display: block;
  padding-bottom: 4px;
  color: color("blue-zodiac");
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  cursor: pointer;
}

.input {
  position: relative;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  transition: border-color $time-transition;
  border: 1px solid color("botticelli");
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;

  &Content {
    position: relative;
  }

  @include hover {
    border-color: color("blue");
  }
}

.hasIcon {
  padding-left: 56px;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: color("gull-gray");

  &Content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: calc(100% - 2px);
    transform: translateY(-50%);
    border-right: 1px solid color("botticelli");
    pointer-events: none;
    user-select: none;
  }
}
